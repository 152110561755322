import _ from 'lodash';

export type LabelName = string;
export type LabelValue = string|number|boolean;
export interface Labels { [key: string]: LabelValue; }

// https://prometheus.io/docs/concepts/data_model/#metric-names-and-labels
// const metric_re = /^[a-zA-Z_:][a-zA-Z0-9_:]*$/u;
const label_re = /^[a-zA-Z_][a-zA-Z0-9_]*$/u;

export function isLabelName( val: any ): val is LabelName {
  return _.isString( val ) && label_re.test( val );
}
export function isLabelValue( val: any ): val is LabelValue {
  return _.isString( val )
    || _.isNumber( val )
    || _.isBoolean( val )
    || _.isNil( val );
}
export function isLabels( val: any ): val is Labels {
  return _.isPlainObject( val ) && _.every( val, ( v, k ) => {
    return isLabelName( k ) && isLabelValue( v );
  } );
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export function labelify( labels: Labels ): string;
export function labelify( key: string, val: string|number|boolean ): string;
export function labelify( key: string|Labels, val?: string|number|boolean ) {
  if ( isLabels( key ) ) {
    return _.map( key, ( v, k ) => labelify( k, v ) ).join( ',' );
  } else {
    return key + '=' + valueify( val );
  }
}

function valueify( val: string|number|boolean ) {
  if ( _.isNumber( val ) ) {
    if ( val === Infinity ) return '"+Inf"';
    if ( val === -Infinity ) return '"-Inf"';
    if ( _.isNaN( val ) ) return '"NaN"';
  }
  return quote( val );
}

export function quote( val: string|number|boolean ): string {
  return '"' + escape( val ).replace( /"/gu, '\\"' ) + '"';
}
export function escape( val: string|number|boolean ): string {
  return String( val ).replace( /\\/gu, '\\\\' ).replace( /\n/gu, '\\n' );
}

export function labels( val: Labels ): Labels {
  if ( ! _.isPlainObject( val ) ) {
    throw new TypeError( 'labels must be plain object' );
  }
  _.every( val, ( v, k ) => {
    return isLabelName( k ) && isLabelValue( v );
  } );
  return _.mapValues( val, ( v, k ) => {
    if ( ! isLabelName( k ) ) {
      throw new TypeError( `Invalid label name "${k}"` );
    }
    if ( isLabelValue( v ) ) {
      return v;
    } else {
      throw new TypeError( `Invalid label value "${v}"` );
    }
  } );
}
