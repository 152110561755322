import _ from 'lodash';
import { Collector, CollectorOptions, Metric } from './Collector';
import { Labels } from './Labels';
import { getarg } from './utils';

export interface HistogramOptions extends CollectorOptions {
  buckets?: number[];
}
export interface HistogramMetricJson {
  labels: Labels;
  values: number[];
  count: number;
  sum: number;
}
export interface HistogramJson {
  type: 'histogram';
  name: string;
  buckets: number[];
  metrics: HistogramMetricJson[];
}
export type HistogramConfig = HistogramOptions & { type: 'histogram' };

export class HistogramMetric extends Metric {

  get type(): 'histogram' { return 'histogram'; }

  values = [];
  sum = 0;
  count = 0;

  readonly buckets: number[] = Histogram.defaultBuckets;

  constructor( labels: Labels, buckets?: number[] ) {
    super( labels );
    if ( buckets ) this.buckets = buckets;
  }

  reset() {
    // Make the skeleton to which values will be saved.
    ( this.values = [] ).length = this.buckets.length;
    this.values.fill( 0 );
    this.sum = 0;
    this.count = 0;
    return this;
  }

  observe( value: number ) {
    if ( ! _.isNumber( value ) ) {
      log.warn( `Cannot observe non-numeric metric` );
      return;
    }
    for ( const [ idx, bucket ] of Object.entries( this.buckets ) ) {
      if ( value <= bucket ) this.values[ idx ]++;
    }
    this.sum += value;
    this.count++;

    return this;
  }

  format( name: string ): string {
    let res: string = '';
    const add = ( key: string, val: number, extra: Labels = {} ) => {
      res += this.label( name + '_' + key, extra ) + ' ' + val + '\n';
    };
    add( 'count', this.count );
    add( 'sum', this.sum );
    for ( const [ idx, le ] of Object.entries( this.buckets ) ) {
      const val = this.values[ idx ];
      add( 'bucket', val, { le } );
    }
    add( 'bucket', this.count, { le : '+Inf' } );
    return res;
  }

  toJSON(): HistogramMetricJson|undefined {
    if ( ! this.count ) return;
    return {
      labels  : this._labels,
      ..._.pick( this, 'values', 'sum', 'count' ),
    };
  }

}

export class Histogram extends Collector<HistogramMetric> {

  // The default bucket sizes, tuned primarily for measuring times in seconds
  static defaultBuckets = [
    0.005, 0.01, 0.025, 0.05, 0.075, 0.1, 0.25, 0.5, 0.75, 1, 2.5, 5, 7.5, 10,
  ];

  get type(): 'histogram' { return 'histogram'; }
  readonly buckets: number[] = Histogram.defaultBuckets;

  constructor( opts: HistogramOptions ) {
    super( opts );
    if ( _.isArray( opts.buckets ) && _.every( opts.buckets, _.isFinite ) ) {
      if ( opts.buckets.length >= 3 ) {
        this.buckets = opts.buckets;
      } else {
        throw new TypeError(
          'Histogram buckets must be array of at least 3 numbers',
        );
      }
    } else if ( opts.buckets ) {
      throw new TypeError( 'Histogram buckets must be array of numbers' );
    } else {
      this.buckets = [ ...Histogram.defaultBuckets ];
    }
    this.observe = this.observe.bind( this );
  }

  isMetric( value: any ): value is HistogramMetric {
    return ( value instanceof HistogramMetric );
  }
  newMetric( labels: Labels ) {
    return new HistogramMetric( labels, this.buckets );
  }

  timer( labels={} ) { return this.makeTimer( 'observe', labels ); }

  observe( value: number, labels: Labels );
  observe( labels: Labels, value: number );
  observe( value: number );
  observe( labels: Labels );
  observe();
  observe( ...args: ( number|Labels )[] ) {
    const value = getarg( 'number', args ) ?? 1;
    if ( ! _.isNumber( value ) ) {
      log.warn( `Cannot observe non-numeric metric (${this.name})` );
      return;
    }
    const labels = getarg( 'labels', args ) || {};
    this.get( labels ).observe( value );
    return this;
  }

  toJSON(): HistogramJson|undefined {
    if ( ! this.metrics.length ) return;
    return {
      type      : this.type,
      name      : this.name,
      buckets   : this.buckets,
      metrics   : _.compact( this.map( m => m.toJSON() ) ),
    };
  }

}
