import _ from 'lodash';
import { mkdebug } from '@ssp/utils';
import { Labels, isLabels } from './Labels';
import {
  ItemName, ItemOptions, ItemType, getType, GroupItemsInit,
  coerceItemsArray,
} from './types';
import { Counter, CounterJson, CounterOptions } from './Counter';
import { Gauge, GaugeJson, GaugeOptions } from './Gauge';
import { Histogram, HistogramJson, HistogramOptions } from './Histogram';

const debug = mkdebug( 'ssp:metrics:collection' );

export interface CollectionOptions {
  name: string;
  labels?: Labels;
  counters?: CounterOptions[] | Record<string, CounterOptions>;
  gauges?: GaugeOptions[] | Record<string, GaugeOptions>;
  histograms?: HistogramOptions[] | Record<string, HistogramOptions>;
}

export interface CollectionJson {
  name: string;
  labels: Labels;
  metrics: ( CounterJson|GaugeJson|HistogramJson )[];
}

export class Collection {

  readonly name: string;

  /** Global labels to apply to all metrics from this collection.. */
  labels: Labels = {};

  #enabled: boolean = true;

  get enabled(): boolean { return this.#enabled; }
  set enabled( enabled: boolean ) {
    const changed = enabled !== this.#enabled;
    this.#enabled = enabled;
    if ( ! enabled ) this.clear();
    if ( changed ) this.children.forEach( c => c.enabled = enabled );
  }
  enable() { this.enabled = true; }
  disable() { this.enabled = false; }

  items: ItemType<ItemName>[] = [];
  aliased_metrics: Map<string, ItemType<ItemName>> = new Map();

  constructor( options: CollectionOptions ) {
    const {
      name,
      labels,
      counters,
      gauges,
      histograms,
      ...opts
    } = options;

    if ( _.isString( name ) ) {
      this.name = name;
    } else {
      throw new TypeError( `Metrics collection name must be a string` );
    }
    if ( isLabels( labels ) ) {
      this.labels = labels;
    } else if ( labels ) {
      throw new TypeError( `Invalid labels "${labels}"` );
    }

    _.assign( this, opts );

    if ( counters ) this.add_counters( counters );
    if ( gauges ) this.add_gauges( gauges );
    if ( histograms ) this.add_histograms( histograms );
  }

  disable_if( ...args: any[] ) {
    for ( const arg of args ) {
      if ( arg ) {
        this.enabled = false;
        return true;
      }
    }
    return false;
  }


  get counters(): ItemType<'counter'>[] {
    return this.get_all( 'counter' );
  }
  counter( name: string ): Counter|undefined;
  counter( opts: CounterOptions ): Counter;
  counter( opts: CounterOptions | string ) {
    if ( _.isString( opts ) ) return this.aliased_metrics.get( opts );
    return this.get_type( 'counter', opts );
  }
  add_counters( opts: GroupItemsInit<'counter'> ) {
    return this.add_types( 'counter', opts );
  }

  get gauges(): ItemType<'gauge'>[] {
    return this.get_all( 'gauge' );
  }
  gauge( name: string ): Gauge|undefined;
  gauge( opts: ItemOptions<'gauge'> ): Gauge;
  gauge( opts: ItemOptions<'gauge'> | string ) {
    if ( _.isString( opts ) ) return this.aliased_metrics.get( opts );
    return this.get_type( 'gauge', opts );
  }
  add_gauges( opts: GroupItemsInit<'gauge'> ) {
    return this.add_types( 'gauge', opts );
  }

  get histograms(): ItemType<'histogram'>[] {
    return this.get_all( 'histogram' );
  }
  histogram( name: string ): Histogram|undefined;
  histogram( opts: ItemOptions<'histogram'> ): Histogram;
  histogram( opts: ItemOptions<'histogram'> | string ) {
    if ( _.isString( opts ) ) return this.aliased_metrics.get( opts );
    return this.get_type( 'histogram', opts );
  }
  add_histograms( opts: GroupItemsInit<'histogram'> ) {
    return this.add_types( 'histogram', opts );
  }

  add_type<K extends ItemName>( type: K, opts: ItemOptions<K> ): ItemType<K> {
    if ( opts.alias && ! opts.name ) opts.name = opts.alias;
    if ( ! opts.name ) throw new Error( 'Metric requires name' );
    opts.name = opts.name.replace( /_+/gu, '_' );
    const Class = getType( type );
    const item = new Class( opts );
    return this.add_item( item );
  }
  add_types<K extends ItemName>( type: K, options: GroupItemsInit<K> ) {
    return coerceItemsArray( type, options )
      .map( opt => this.add_type( type, opt ) );
  }
  find_type<K extends ItemName>(
    type: K, name: string,
  ): ItemType<K>|undefined {
    for ( const item of this.items ) {
      if ( item.type === type && item.name === name ) {
        return item as ItemType<K>;
      }
    }
  }
  get_type<K extends ItemName>( type: K, opts: ItemOptions<K> ): ItemType<K> {
    return this.find_type( type, opts.name ) || this.add_type( type, opts );
  }
  get_all<K extends ItemName>( type: K ): ItemType<K>[] {
    return _.filter( this.items, { type } ) as ItemType<K>[];
  }
  add_item<T extends ItemType<ItemName>>( item: T ): T {
    if ( item.alias ) this.aliased_metrics.set( item.alias, item );
    this.items.push( item );
    return item;
  }

  get<K extends ItemName>( opts: ItemOptions<K> & { type: K } ): ItemType<K> {
    return this.get_type( opts.type, opts );
  }
  find<K extends ItemName>( opts: { type: K; name: string; } ) {
    return this.find_type( opts.type, opts.name );
  }
  add<K extends ItemName>( opts: ItemOptions<K> & { type: K } ): ItemType<K> {
    return this.add_type( opts.type, opts );
  }

  get children(): Collection[] { return []; }

  invokeSync(
    method: string, items: boolean = true, children: boolean = true,
  ) {
    const targets = [];
    if ( items ) targets.push( ...this.items );
    if ( children ) targets.push( ...this.children );
    return _.map( targets, child => {
      if ( ! child.enabled ) return;
      if ( ! _.isFunction( child[ method ] ) ) return;
      debug( `invoking ${method} for ${child}` );
      try {
        return child[ method ]();
      } catch ( err ) {
        log.error( `Invoking ${method} (sync) for ${child} failed:`, err );
      }
    } );
  }

  invokeAsync(
    method: string, items: boolean = true, children: boolean = true,
  ) {
    const targets = [];
    if ( items ) targets.push( ...this.items );
    if ( children ) targets.push( ...this.children );
    return Promise.all( _.map( targets, async child => {
      if ( ! child.enabled ) return;
      if ( ! _.isFunction( child[ method ] ) ) return;
      debug( `invoking ${method} for ${child}` );
      try {
        return await child[ method ]();
      } catch ( err ) {
        log.error( `Invoking ${method} (async) for ${child} failed:`, err );
      }
    } ) );
  }

  async collect() { await this.invokeAsync( 'collect' ); }
  format() { return this.invokeSync( 'format' ).join( '\n' ); }

  async init(): Promise<void> { await this.invokeAsync( 'init' ); }
  async start(): Promise<void> { await this.invokeAsync( 'start' ); }
  async stop(): Promise<void> { await this.invokeAsync( 'stop' ); }

  /**
   * Reset all the metrics in this collection.  This resets all the
   * metrics values to 0, but keeps the metrics themselves, so that
   * the zeros will get reported.
   */
  reset() {
    this.items.map( c => c.reset() );
    this.children.map( c => c.reset() );
    return this;
  }
  /**
   * Clear all the metrics in this collection.  This does not keep the
   * metrics entries, so zero values won't be reported for old
   * entries, it just removes all the metrics and allows you to start
   * over with a clean slate.
   */
  clear() {
    this.items.map( c => c.clear() );
    this.children.map( c => c.clear() );
    return this;
  }

  getAllItems() {
    return [
      ...this.items,
      ..._.flatMap( this.children, child => child.getAllItems() ),
    ];
  }

  toJSON(): CollectionJson {
    return {
      name    : this.name,
      labels  : this.labels,
      metrics : _.compact( _.flatMap( this.getAllItems(), c => c.toJSON() ) ),
    };
  }
  toString() { return `${this.constructor.name}<${this.name}>`; }

  async aggregate() {
    const items = this.getAllItems();
    const res: CollectionJson = {
      name    : this.name,
      labels  : this.labels,
      metrics : await Promise.all( _.map( items, async item => {
        if ( ! item.enabled ) return;
        try {
          return await item.aggregate();
        } catch ( err ) {
          log.error( `Invoking aggregate (async) for ${item} failed:`, err );
        }
      } ) ),
    };
    return res;
  }

}
