import { resolveURL } from '@ssp/utils';
import {
  AggregatorRegistry, AggregatorRegistryOptions,
} from './AggregatorRegistry';

import type { RegistryJson } from './Registry';

export interface BrowserRegistryOptions extends AggregatorRegistryOptions {
  url?: string;
}

export class BrowserRegistry extends AggregatorRegistry {

  declare url: string;

  constructor( options: BrowserRegistryOptions = {} ) {
    const { url, ...opts } = options;
    super( opts );
    this.url = resolveURL( url || '/api/metrics/browser-aggregator' );
  }

  async emitter( data: RegistryJson ) {
    const req = new XMLHttpRequest();
    req.addEventListener( 'error', ( err ) => {
      log.debug( '@ssp/metrics BrowserRegistry emitter error:', err );
    } );
    req.open( 'POST', this.url, true );
    req.setRequestHeader( 'Content-type', 'application/json' );
    req.send( JSON.stringify( data ) );
  }

}
