import { Collection, CollectionOptions } from './Collection';

export interface BundleOptions extends Omit<CollectionOptions, 'name'> {
}

export interface Bundle {
  setup?(): void;
}

export type Os = 'linux' | 'darwin';

export class Bundle extends Collection {

  constructor( options: BundleOptions = {} ) {
    super( { ...options, name : new.target.name } );
    // if ( typeof this.setup === 'function' ) this.setup();
  }

}
