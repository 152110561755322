import { metrics } from './common';
import {
  browser_performance,
} from './bundles/client';

export * from './common';

export * from './BrowserRegistry';

export default metrics;

export const standard_bundles = {
  browser_performance,
} as const;

export function loadStandardBundles() {
  Object.values( standard_bundles )
    .forEach( bundle => metrics.bundle( bundle ) );
}
