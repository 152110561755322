import { Metrics } from './Metrics';

export * from './types';
export * from './Metrics';
export * from './Collector';
export * from './Counter';
export * from './Gauge';
export * from './Histogram';
export * from './Labels';
export * from './Registry';
export * from './AggregatorRegistry';
export * from './Bundle';
export * from './Collection';
export * from './bundles';
export * from './utils';

export const metrics = new Metrics( { name : 'default' } );

/**
 * The SimpleJson format is used for some test instrumentation.  It
 * only supports counters and gauges and provides metrics in a much
 * simplified format that doesn't include things like inherited labels.
 */
export interface SimpleJson {
  [name: string]: Record<string, number>;
}
