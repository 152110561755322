import _ from 'lodash';
import { Collector, CollectorOptions, Metric } from './Collector';
import { Labels } from './Labels';
import { getarg } from './utils';

export interface GaugeOptions extends CollectorOptions {
}

export interface GaugeMetricJson {
  labels: Labels;
  value: number;
}
export interface GaugeJson {
  type: 'gauge';
  name: string;
  metrics: GaugeMetricJson[];
}
export class GaugeMetric extends Metric {

  get type(): 'gauge' { return 'gauge'; }

  value = 0;

  set( value ) {
    this.value = value;
    return this;
  }

  inc( count: number = 1 ) {
    this.value += count;
    return this;
  }

  dec( count: number = 1 ) {
    this.value -= Math.abs( count );
    return this;
  }

  toJSON(): GaugeMetricJson|undefined {
    if ( ! this.value ) return;
    return { labels : this._labels, value : this.value };
  }

}

export class Gauge extends Collector<GaugeMetric> {

  get type(): 'gauge' { return 'gauge'; }

  isMetric( value: any ): value is GaugeMetric {
    return ( value instanceof GaugeMetric );
  }
  newMetric( labels: Labels ) { return new GaugeMetric( labels ); }

  set( value: number, labels: Labels );
  set( labels: Labels, value: number );
  set( value: number );
  set( labels: Labels );
  set();
  set( ...args: ( number|Labels )[] ) {
    const value = getarg( 'number', args );
    const labels = getarg( 'labels', args ) || {};
    this.get( labels ).set( value );
    return this;
  }

  inc( count: number, labels: Labels );
  inc( labels: Labels, count: number );
  inc( count: number );
  inc( labels: Labels );
  inc();
  inc( ...args: ( number|Labels )[] ) {
    const value = getarg( 'number', args ) ?? 1;
    const labels = getarg( 'labels', args ) || {};
    this.get( labels ).inc( value );
    return this;
  }

  dec( count: number, labels: Labels );
  dec( labels: Labels, count: number );
  dec( count: number );
  dec( labels: Labels );
  dec();
  dec( ...args: ( number|Labels )[] ) {
    const value = getarg( 'number', args ) ?? 1;
    const labels = getarg( 'labels', args ) || {};
    this.get( labels ).dec( value );
    return this;
  }

  timer( labels: Labels = {} ) { return this.makeTimer( 'set', labels ); }

  toJSON(): GaugeJson|undefined {
    if ( ! this.metrics.length ) return;
    return {
      type      : this.type,
      name      : this.name,
      metrics   : _.compact( this.map( m => m.toJSON() ) ),
    };
  }

}
