import _ from 'lodash';
import { mkdebug, init, getEnv, squish } from '@ssp/utils';
import semver from 'semver/functions/parse';
import platform from 'platform';

import { Counter } from './Counter';
import { Gauge } from './Gauge';
import { Histogram } from './Histogram';
import { Bundle } from './Bundle';
import { Collection } from './Collection';
import { ItemOptions } from './types';
import { Registry } from './Registry';

import type { Squishy } from '@ssp/utils';

import type { Labels } from './Labels';
import type { BundleOptions } from './Bundle';
import type { CollectionOptions } from './Collection';
import type {
  Bundles, BundleCtor, BundleFromCtor, BundleName,
} from './bundles';

const debug = mkdebug( 'ssp:metrics:metrics' );

export interface MetricsOptions extends CollectionOptions {
  bundles?: Squishy<Bundle | BundleCtor>
}

export class Metrics extends Collection {

  #registry: Registry = new Registry();

  get registry() { return this.#registry; }
  set registry( registry: Registry ) {
    const old = this.#registry;
    this.#registry = registry;
    registry.add( this );
    registry.merge( old );
  }

  bundles: Bundles = {} as Bundles;

  constructor( options: MetricsOptions ) {
    const { bundles, ...opts } = options;
    super( opts );
    for ( const b of squish( bundles ) ) this.bundle( b as any );
    init.register( {
      id      : 'metrics',
      after   : [ 'daemon', 'config' ],
      before  : [ '@end' ],
      context : this,
    } );
  }

  get children(): Bundle[] {
    return _.values( this.bundles ) as Bundle[];
  }

  bundle<B extends Bundle>( bundle: B ): B;
  bundle<C extends BundleCtor>(
    ctor: C,
    opts?: Partial<BundleOptions>,
  ): BundleFromCtor<C>;
  bundle<N extends BundleName>( name: N ): Bundles[N] | undefined;
  bundle(
    bundle: BundleName | BundleCtor | Bundle,
    opts?: Partial<BundleOptions>,
  ) {
    if ( typeof bundle === 'string' ) return this.bundles[ bundle ];
    if ( typeof bundle === 'function' ) bundle = new ( bundle as any )( opts );
    if ( ! ( bundle instanceof Bundle ) ) {
      throw new TypeError( `Unknown bundle "${bundle}"` );
    }
    if ( bundle.setup ) bundle.setup();
    const name = bundle.name;
    if ( this.bundles[ name ] ) return bundle;
    this.bundles[ name ] = bundle;
    return bundle;
  }

  add_labels( labels: Labels ) { _.assign( this.labels, labels ); }

  appinfo( info: Labels ) {
    _.defaults( info, {
      platform  : platform.name,
      env       : getEnv(),
    } );
    return this.info_gauge( 'appinfo', info, 'Application Information' );
  }

  record_version( name: string, version: string, help?: string ) {
    const info = semver( version );
    const labels: Labels = _.pick( info, 'version', 'major', 'minor', 'patch' );
    if ( info.prerelease ) {
      const pre = info.prerelease;
      if ( pre[0] === 'pre' ) {
        labels.pre = pre[1];
      } else {
        labels.pre = pre.join( '.' );
      }
    }
    if ( ! name.endsWith( '_version' ) ) name += '_version';
    return this.info_gauge(
      name + '_version',
      labels,
      help || 'Version Information',
    );
  }

  info_gauge( name: string, labels: Labels, help?: string ) {
    this.gauge( { name, help, resettable : false } ).set( Date.now(), labels );
    return this;
  }

  counter( name: string ): Counter|undefined;
  counter( opts: ItemOptions<'counter'> ): Counter;
  counter( opts: string|ItemOptions<'counter'> ) {
    if ( typeof opts === 'string' ) {
      return this.find_type( 'counter', opts );
    } else {
      return this.add_type( 'counter', opts );
    }
  }

  gauge( name: string ): Gauge|undefined;
  gauge( opts: ItemOptions<'gauge'> ): Gauge;
  gauge( opts: string|ItemOptions<'gauge'> ) {
    if ( typeof opts === 'string' ) {
      return this.find_type( 'gauge', opts );
    } else {
      return this.add_type( 'gauge', opts );
    }
  }

  histogram( name: string ): Histogram|undefined;
  histogram( opts: ItemOptions<'histogram'> ): Histogram;
  histogram( opts: string|ItemOptions<'histogram'> ) {
    if ( typeof opts === 'string' ) {
      return this.find_type( 'histogram', opts );
    } else {
      return this.add_type( 'histogram', opts );
    }
  }

  getCollectableItems() {
    return [ ...this.items, ..._.values( this.bundles ) ];
  }

  async init(): Promise<void> {
    await this.#registry.init();
  }
  async start(): Promise<void> {
    await this.#registry.start();
    await this.invokeAsync( 'start' );
  }
  async stop(): Promise<void> {
    await this.invokeAsync( 'stop' );
    await this.#registry.stop();
  }

  toString(): string { return `Metrics<${this.name}>`; }
}
