import _ from 'lodash';
import { Labels, isLabels } from './Labels';

export function getarg( type: 'number', args: any[] ): number|undefined;
export function getarg( type: 'labels', args: any[] ): Labels|undefined;
export function getarg( type: 'number'|'labels', args: any ) {
  for ( const arg of args ) {
    if ( type === 'number' && _.isNumber( arg ) ) return arg;
    if ( type === 'labels' && _.isPlainObject( arg ) ) {
      if ( isLabels( arg ) ) return arg;
      log.warn( 'Returning', arg, 'but it does not fulfill isLabels' );
      return arg;
    }
  }
}

export function bucketgen(
  type: 'linear'|'exponential',
  start: number,
  factor: number,
  count: number = 10,
) {
  if ( start < 1 ) {
    throw new TypeError( `bucketgen start must be positive` );
  }
  if ( count < 1 ) {
    throw new TypeError( `bucketgen count must be positive` );
  }
  if ( factor < 1 ) {
    throw new TypeError( `bucketgen factor must be positive` );
  }
  return Array.from( { length : count }, () => {
    const val = start;
    if ( type === 'exponential' ) {
      start *= factor;
    } else {
      start += factor;
    }
    return val;
  } );
}

export function isNumbers( arg: any ): arg is number[] {
  return Array.isArray( arg ) && arg.every( x => ( typeof x === 'number' ) );
}

export interface ExpressLikeApp {
  post( url: string, cb: ( req: any, res: any, next?: any ) => void );
  get( url: string, cb: ( req: any, res: any, next?: any ) => void );
  use( cb: ( req: any, res: any, next?: any ) => void );
}
