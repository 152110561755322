import _ from 'lodash';
import { Counter, CounterOptions } from './Counter';
import { Gauge, GaugeOptions } from './Gauge';
import { Histogram, HistogramOptions } from './Histogram';

const TYPES = {
  counter   : Counter,
  gauge     : Gauge,
  histogram : Histogram,
};

export interface ItemOptionsMap {
  counter:  CounterOptions;
  gauge:  GaugeOptions;
  histogram:  HistogramOptions;
}
export interface ItemTypeMap {
  counter:  Counter;
  gauge:  Gauge;
  histogram:  Histogram;
}
export type ItemName = keyof ItemOptionsMap;
export type AnyOptions = ItemOptionsMap[ItemName];
export type AnyItem = ItemTypeMap[ItemName];

export type ItemOptions<K extends ItemName>
  = ItemOptionsMap[K] & { name?: string; };
export type ItemType<K extends ItemName>
  = ItemTypeMap[K];

export type GroupItemsArray<K extends ItemName>
  = ItemOptions<K>[];
export type GroupItemsObject<K extends ItemName>
  = Record<string, ItemOptions<K> & { name?: string; }>;

export type GroupItemsInit<K extends ItemName> =
  | GroupItemsArray<K> | GroupItemsObject<K>; // | ItemType<K>[];

export type ItemClass<K extends ItemName>
  = new ( opts: ItemOptions<K> ) => ItemType<K>;

export function coerceItemsArray<K extends ItemName>(
  type: K, val: any,
): GroupItemsArray<K> {
  if ( _.isPlainObject( val ) ) {
    val = _.map( val, ( opts, alias ) => ( { alias, ...opts } ) );
  }
  return _.map( val, opts => _.defaults( opts, { type } ) );
}
export function getType<K extends ItemName>( type: K ): ItemClass<K> {
  return TYPES[ type ] as ItemClass<K>;
}
