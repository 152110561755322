import _ from 'lodash';
import { Bundle } from '../Bundle';

declare module '.' {
  export interface Bundles {
    browser_performance: browser_performance;
  }
}
export class browser_performance extends Bundle  {

  setup() {
    this.disable_if(
      ( typeof performance !== 'object' ),
      ( ! performance.timing ),
    );
  }

  async start() {
    const start = performance.timing.navigationStart;
    _.each( performance?.timing, ( value, key ) => {
      if ( ! ( value && _.isNumber( value ) ) ) return;
      const name = 'performace_timing_' + key + '_seconds';
      value = ( value - start ) / 1000;
      log.debug( 'PERFORMANCE METRICS:', name, value );
      this.histogram( {
        name,
        help  : `Time between navigationStart and ${key} in seconds`,
      } ).observe( ( value - start ) / 1000 );
    } );
  }

}
