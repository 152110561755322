import _ from 'lodash';
import { Labels, labelify, escape } from './Labels';

type ItemName = 'counter' | 'gauge' | 'histogram';

export abstract class Metric {

  value: number = 0;

  protected readonly _labels: Labels;

  abstract get type(): ItemName;

  constructor( labels: Labels ) {
    this._labels = labels;
  }

  format( name: string ) {
    if ( ! _.isNumber( this.value ) ) return;
    return this.label( name ) + ' ' + this.value;
  }

  label( name: string, extra_labels: Labels = {} ): string {
    return name + this.labels( extra_labels );
  }
  simple_label( omit: string[] = [] ) {
    const labels = { ...this._labels };
    for ( const o of omit ) {
      delete labels[ o ];
    }
    return labelify( labels ).replace( /"/gu, '' );
  }

  reset() {
    this.value = 0;
    return this;
  }

  labels( more: Labels = {} ) {
    const labels = labelify( { ...this._labels, ...more } );
    if ( labels.length ) return '{' + labels + '}';
    return '';
  }

  matches( labels: Labels ) { return _.isEqual( this._labels, labels ); }
}

export interface CollectorOptions {
  name?: string;
  alias?: string;
  help?: string;
  unit?: string;
  labels?: string[];
  resettable?: boolean;
  collect?: ( this: Collector ) => void|Promise<void>;
  init?: ( this: Collector ) => void|Promise<void>;
}

export interface Collector {
  collect?(): void|Promise<void>;
}
export abstract class Collector<M extends Metric = Metric> {

  metrics: M[] = [];
  name: string;
  alias?: string;
  help?; string;
  unit?: string;
  resettable: boolean = true;

  /** Currently stored for documentation purposes only. */
  labelNames?: string[];
  #enabled: boolean = true;

  get enabled(): boolean { return this.#enabled; }
  set enabled( enabled: boolean ) {
    this.#enabled = enabled;
    if ( ! enabled ) this.clear();
  }

  abstract get type(): ItemName;

  declare init: ( this: Collector ) => void|Promise<void>;

  constructor( options: CollectorOptions ) {
    const {
      name, alias, help, unit, labels, resettable, collect, init,
    } = options;
    if ( _.isString( name ) ) {
      this.name = name;
    } else {
      throw new TypeError( 'Collector requires name' );
    }
    if ( _.isString( help ) ) {
      this.help = help;
    } else if ( help ) {
      throw new TypeError( `Collector help must be string (${name})` );
    }
    if ( _.isString( unit ) ) {
      if ( ! name.endsWith( '_' + unit ) ) {
        throw new TypeError( [
          `Collector with unit "${unit}" must have name ending with "_${unit}"`,
          `("${name}")`,
        ].join( ' ' ) );
      }
      this.unit = unit;
    } else if ( unit ) {
      throw new TypeError( `Collector unit must be string (${name})` );
    }
    if ( _.isArray( labels ) ) {
      this.labelNames = labels;
    } else if ( labels ) {
      throw new TypeError(
        `Collector labels must be array of strings (${name})`,
      );
    }
    if ( _.isBoolean( resettable ) ) this.resettable = resettable;
    if ( _.isFunction( collect ) ) {
      this.collect = collect;
    } else if ( collect ) {
      throw new TypeError(
        `Collector collect option must be function (${name})`,
      );
    }
    if ( _.isFunction( init ) ) {
      this.init = init;
    } else if ( init ) {
      throw new TypeError( `Collector init option must be function (${name})` );
    }
    if ( _.isString( alias ) ) this.alias = alias;
  }

  abstract isMetric( value: any ): value is M;
  abstract newMetric( labels: Labels ): M;

  labels( labels: Labels ) {
    return this.get( labels );
  }
  get( labels: Labels = {} ): M {
    return this.find( labels ) || this.new( labels );
  }

  new( labels: Labels={} ): M {
    const metric = this.newMetric( labels );
    metric.reset();
    this.metrics.push( metric );
    return metric;
  }

  find( labels: Labels = {} ): M|undefined {
    if ( this.isMetric( labels ) ) return labels;
    for ( const metric of this.metrics ) {
      if ( metric.matches( labels ) ) return metric;
    }
  }

  abstract toJSON(): any;

  async aggregate() {
    if ( typeof this.collect === 'function' ) await this.collect();
    const res = this.toJSON();
    this.clear();
    return res;
  }

  reset() {
    if ( this.resettable ) this.map( x => x.reset() );
    return this;
  }
  clear() { this.metrics.length = 0; }

  map<T=unknown>( cb: string ): T[];
  map<T=unknown>( cb: ( metric: M ) => T ): T[];
  map<T=unknown>( cb: string | ( ( metric: M ) => T ) ): T[] {
    if ( _.isString( cb ) ) return _.map( this.metrics, cb );
    if ( _.isFunction( cb ) ) return _.map( this.metrics, cb );
  }

  format() {
    if ( ! this.enabled ) return;
    const { name, type } = this;
    const res = [ `# TYPE ${name} ${type}` ];
    if ( _.isString( this.unit ) && this.unit.length ) {
      res.push( `# UNIT ${this.name} ${escape( this.help )}` );
    }
    if ( _.isString( this.help ) && this.help.length ) {
      res.push( `# HELP ${this.name} ${escape( this.help )}` );
    }
    res.push( ...this.map( metric => metric.format( name ) ) );
    res.push( '' );
    return res.join( '\n' );
  }

  makeTimer( recorder, start_labels={} ) {
    const start = Date.now();
    return ( stop_labels: Labels = {} ) => {
      const end = Date.now();
      const duration = ( end - start ) / 1000;
      this[ recorder ]( duration, { ...start_labels, ...stop_labels } );
    };
  }

  replace( data: { labels: Labels, value: number }[] ) {
    const copy = Object.create( this, {
      metrics : {
        enumerable    : true,
        writable      : true,
        configurable  : true,
        value         : [],
      },
    } );
    _.each( data, ( { labels, value } ) => copy.set( labels, value ) );
    this.metrics = copy.metrics;
  }

  toString() { return `${this.type}<${this.name}>`; }

}
