import { mkdebug } from '@ssp/utils';
import { Registry, RegistryOptions, RegistryJson } from './Registry';

const debug = mkdebug( 'ssp:metrics:aggregator-registry' );

export interface AggregatorRegistryOptions extends RegistryOptions {
  interval?: number;
  // Optional because you might just override it in a subclass
  emitter?: ( data: RegistryJson ) => void|Promise<void>;
}

export class AggregatorRegistry extends Registry {

  interval: number = 10000;
  emitter( data: RegistryJson ): void|Promise<void> {
    void data;
    throw new Error( [
      `AggregatorRegistry must be subclassed or instantiated with`,
      `'emitter' method`,
    ].join( ' ' ) );
  }
  declare _interval: ReturnType<typeof setInterval>;

  constructor( options: AggregatorRegistryOptions ) {
    const { interval, emitter, ...opts } = options;
    super( opts );
    if ( typeof interval === 'number' ) this.interval = interval;
    if ( typeof emitter === 'function' ) this.emitter = emitter;
    if ( typeof this.emitter !== 'function' ) {
      throw new TypeError( `AggregatorRegistry emitter must be function` );
    }
  }

  async start() {
    this._interval = setInterval( () => this.send_aggregate(), this.interval );
  }

  async stop() { clearInterval( this._interval ); }

  async aggregate(): Promise<RegistryJson> {
    const items = Array.from( this.item_map.values() );
    const res: RegistryJson = {
      labels      : this.labels,
      collections : items.map( item => item.toJSON() ),
    };
    return res;
  }

  async send_aggregate() {
    const data = await this.aggregate();
    debug( 'EMITTING:', data );
    return this.emitter( data );
  }

}
