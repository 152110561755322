import _ from 'lodash';
import { mkdebug, NotFound } from '@ssp/utils';
import { Collection, CollectionJson } from './Collection';
import { Labels } from './Labels';

const debug = mkdebug( 'ssp:metrics:registry' );

export interface RegistryOptions {
  labels?: Labels;
  openmetrics?: boolean;
}
export interface RegistryJson {
  labels: Labels;
  collections: CollectionJson[];
}

export class Registry {

  /** Enable enhanced support for OpenMetrics
   * https://github.com/OpenObservability/OpenMetrics
   * Beware: Prometheus doesn't appear to actually support this
   * format, every scrape fails with `"INVALID" "\n" is not a valid
   * start token`
   */
  openmetrics: boolean = false;
  labels: Labels = {};

  item_map: Map<string, Collection> = new Map();

  get content_type() {
    if ( this.openmetrics ) {
      return 'application/openmetrics-text; version=0.0.1';
    } else {
      return 'text/plain; version=0.0.4';
    }
  }

  constructor( opts: RegistryOptions = {} ) { _.assign( this, opts ); }

  add( item: Collection ): void {
    if ( ! ( item instanceof Collection ) ) {
      throw new TypeError( `Registry.add requires collection instance` );
    }
    if ( this.item_map.has( item.name ) ) {
      if ( this.item_map.get( item.name ) === item ) return;
      throw new TypeError(
        `An item named "${item.name}" is already registered`,
      );
    }
    this.item_map.set( item.name, item );
  }

  merge( registry: Registry ): void {
    if ( _.isNil( registry ) ) return;
    if ( ! ( registry instanceof Registry ) ) {
      throw new TypeError( 'Registry.merge can only merge other Registries' );
    }
    for ( const item of registry.item_map.values() ) this.add( item );
  }

  has( name: string ): boolean { return this.item_map.has( name ); }
  get( name: string ): Collection {
    const item = this.item_map.get( name );
    if ( item instanceof Collection ) return item;
    throw new NotFound( `No registered item with name "${name}"` );
  }

  items( name: string = 'default' ) { return this.get( name ).items; }

  async collect( name: string = 'default' ) {
    const item = this.get( name );
    debug( `collecting: ${item}` );
    try {
      await item.collect();
    } catch ( err ) {
      log.error( `Collect method for ${item} failed:`, err );
    }
  }

  format( name: string = 'default' ) { return this.get( name ).format(); }
  format_json( name: string = 'default' ) { return this.get( name ).toJSON(); }

  toJSON(): RegistryJson {
    const items = Array.from( this.item_map.values() );
    return {
      labels      : this.labels,
      collections : items.map( item => item.toJSON() ),
    };
  }

  reset( name: string = 'default' ) { this.get( name ).reset(); }
  clear( name: string = 'default' ) { this.get( name ).clear(); }

  async collect_formatted( name: string = 'default' ) {
    await this.collect( name );
    return this.format( name );
  }

  async collect_json( name: string = 'default' ) {
    await this.collect( name );
    return this.format_json( name );
  }

  async init() { /* no-op */ }
  async start() { /* no-op */ }
  async stop() { /* no-op */ }
}
