import _ from 'lodash';
import { Collector, CollectorOptions, Metric } from './Collector';
import { Labels } from './Labels';
import { getarg } from './utils';

export interface CounterOptions extends CollectorOptions {
}

export interface CounterMetricJson {
  labels: Labels;
  value: number;
}
export interface CounterJson {
  type: 'counter';
  name: string;
  metrics: CounterMetricJson[];
}
export type CounterConfig = CounterOptions & { type: 'counter' };

export class CounterMetric extends Metric {

  get type(): 'counter' { return 'counter'; }

  value = 0;

  inc( count: number = 1 ) {
    if ( count < 0 ) {
      throw new TypeError( `Counter.inc can only move forward` );
    }
    this.value += count;
    return this;
  }

  toJSON(): CounterMetricJson|undefined {
    if ( ! this.value ) return;
    return { labels : this._labels, value : this.value };
  }

}

export class Counter extends Collector<CounterMetric> {

  get type(): 'counter' { return 'counter'; }

  inc( count: number, labels: Labels );
  inc( labels: Labels, count: number );
  inc( count: number );
  inc( labels: Labels );
  inc();
  inc( ...args: ( number|Labels )[] ) {
    const value = getarg( 'number', args ) ?? 1;
    const labels = getarg( 'labels', args ) || {};
    this.get( labels ).inc( value );
    return this;
  }

  isMetric( value: any ): value is CounterMetric {
    return ( value instanceof CounterMetric );
  }
  newMetric( labels: Labels ) { return new CounterMetric( labels ); }

  constructor( options: CounterOptions ) {
    super( options );
    if ( ! this.name.endsWith( '_total' ) ) {
      log.warn( `Counters should have "_total" suffix (${this.name})` );
    }
  }

  toJSON(): CounterJson|undefined {
    if ( ! this.metrics.length ) return;
    return {
      type      : this.type,
      name      : this.name,
      metrics   : _.compact( this.map( m => m.toJSON() ) ),
    };
  }

}
